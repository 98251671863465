/**
 * in this file I only kept the holidays that I need for the phone nesto business hours component
 * We dont need to support all the holidays in the app
 * ex: Groundhog Day, Mothers Day, etc
 * So All type `type: "observance", "optional",` has been removed to keep the file small
 *
 * nesto office holidays calendar
 * @ref https://nesto.helpjuice.com/en_US/temps-de-cong%C3%A9/1790850-untitled-article
 *
 * Parser of this file
 * @ref https://github.com/commenthol/date-holidays-parser
 *
 * Source of holidays
 * date-holidays is a CLI to generate the list of holidays in a given country
 * @ref https://github.com/commenthol/date-holidays/blob/master/data/countries/CA.yaml
 */
const holidays = {
  version: "2024-06-10",
  license: "CC-BY-SA-3",
  holidays: {
    CA: {
      names: {
        en: "Canada",
        fr: "Canada",
      },
      zones: [
        "America/Toronto",
        "America/St_Johns",
        "America/Halifax",
        "America/Glace_Bay",
        "America/Moncton",
        "America/Goose_Bay",
        "America/Blanc-Sablon",
        "America/Nipigon",
        "America/Thunder_Bay",
        "America/Iqaluit",
        "America/Pangnirtung",
        "America/Resolute",
        "America/Atikokan",
        "America/Rankin_Inlet",
        "America/Winnipeg",
        "America/Rainy_River",
        "America/Regina",
        "America/Swift_Current",
        "America/Edmonton",
        "America/Cambridge_Bay",
        "America/Yellowknife",
        "America/Inuvik",
        "America/Creston",
        "America/Dawson_Creek",
        "America/Fort_Nelson",
        "America/Vancouver",
        "America/Whitehorse",
        "America/Dawson",
      ],
      langs: ["en", "fr"],
      days: {
        "01-01": {
          _name: "01-01",
        },
        "01-02": {
          _name: "01-02",
        },
        "easter -2": {
          _name: "easter -2",
        },
        easter: {
          _name: "easter",
        },
        "monday before 05-25": {
          name: {
            en: "Victoria Day",
            fr: "Fête de la Reine",
          },
        },
        "07-01": {
          name: {
            en: "Canada Day",
            fr: "Fête du Canada",
          },
        },
        "monday after 08-01": {
          name: {
            en: "Civic Holiday",
            fr: "Premier lundi d’août",
          },
        },
        "1st monday in September": {
          _name: "05-01",
        },
        "09-30 since 2021": {
          name: {
            en: "National Day for Truth and Reconciliation",
            fr: "Journée nationale de la vérité et de la réconciliation",
          },
        },
        "2nd monday after 10-01": {
          name: {
            en: "Thanksgiving",
            fr: "Action de grâce",
          },
        },
        "11-11": {
          name: {
            en: "Remembrance Day",
            fr: "Jour du Souvenir",
          },
        },
        "12-25": {
          _name: "12-25",
        },
        "12-26": {
          _name: "12-26",
        },
        // Between Christmas and New Year's Day
        // from Dec 27 to Dec 31 inclusive
        // next rules apply will be `01-01` and `01-02`
        "12-27 P5D": {
          name: {
            en: "Nesto Christmas break",
            fr: "Nesto congé de Noël",
          },
        },
      },
      states: {
        AB: {
          name: "Alberta",
          zones: ["America/Edmonton"],
          days: {
            "3rd monday after 02-01": {
              name: {
                en: "Family Day",
                fr: "Fête de la famille",
              },
            },
            "monday after 08-01": {
              name: {
                en: "Heritage Day",
                fr: "Fête du patrimoine",
              },
              type: "bank",
            },
          },
        },
        BC: {
          name: "British Columbia",
          zones: [
            "America/Vancouver",
            "America/Edmonton",
            "America/Creston",
            "America/Dawson_Creek",
          ],
          days: {
            "2nd monday after 02-01": {
              name: {
                en: "Family Day",
                fr: "Fête de la famille",
              },
              active: [
                {
                  to: 2019,
                },
              ],
            },
            "3rd monday after 02-01": {
              name: {
                en: "Family Day",
                fr: "Fête de la famille",
              },
              active: [
                {
                  from: 2019,
                },
              ],
            },
          },
        },
        MB: {
          name: "Manitoba",
          zones: ["America/Winnipeg"],
          days: {
            "3rd monday after 02-01": {
              name: {
                en: "Louis Riel Day",
                fr: "Journée Louis Riel",
              },
            },
            // Civic Holiday
            "monday after 08-01": false,
            // Remembrance Day
            "11-11": false,
          },
        },
        NB: {
          name: "New Brunswick",
          zones: ["America/Moncton"],
          days: {
            "3rd monday after 02-01": {
              name: {
                en: "Family Day",
                fr: "Fête de la famille",
              },
            },
            "monday after 08-01": {
              name: {
                en: "New Brunswick Day",
                fr: "Jour de Nouveau Brunswick",
              },
            },
            // National Day for Truth and Reconciliation
            "09-30 since 2021": false,
          },
        },
        NL: {
          name: "Newfoundland and Labrador",
          zones: ["America/St_Johns", "America/Goose_Bay"],
          days: {
            "11-11": {
              name: {
                en: "Armistice Day",
                fr: "Jour de l'Armistice",
              },
            },
          },
        },
        NS: {
          name: "Nova Scotia",
          zones: ["America/Halifax", "America/Moncton"],
          days: {
            "3rd monday after 02-01": {
              name: {
                en: "Heritage Day",
                fr: "Fête du Patrimoine",
              },
            },
            // civic holiday and is not a statutory holiday
            // "monday after 08-01": {
            //   name: {
            //     en: "Natal Day",
            //     fr: "Jour de la Fondation",
            //   },
            // },
            // Civic Holiday
            "monday after 08-01": false,
            // National Day for Truth and Reconciliation
            "09-30 since 2021": false,
            // Remembrance Day
            "11-11": false,
          },
        },
        NT: {
          name: "Northwest Territories",
          zones: ["America/Yellowknife", "America/Inuvik"],
          days: {
            "06-21": {
              name: {
                en: "National Indigenous Peoples Day",
                fr: "Journée nationale des Autochthones",
              },
            },
          },
        },
        NU: {
          name: "Nunavut",
          zones: [
            "America/Iqaluit",
            "America/Pangnirtung",
            "America/Resolute",
            "America/Rankin_Inlet",
            "America/Atikokan",
            "America/Cambridge_Bay",
          ],
          days: {
            "07-09": {
              name: {
                en: "Nunavut Day",
              },
              type: "bank",
            },
          },
        },
        ON: {
          name: "Ontario",
          zones: [
            "America/Toronto",
            "America/Nipigon",
            "America/Thunder_Bay",
            "America/Atikokan",
            "America/Rainy_River",
            "America/Winnipeg",
          ],
          days: {
            "3rd monday after 02-01": {
              name: {
                en: "Family Day",
                fr: "Fête de la famille",
              },
            },
            // National Day for Truth and Reconciliation
            "09-30 since 2021": false,
            // Remembrance Day
            "11-11": false,
          },
        },
        PE: {
          name: "Prince Edward Island",
          days: {
            "3rd monday after 02-01": {
              name: {
                en: "Islander Day",
                fr: "Fête des Insulaires",
              },
            },
            "3rd friday after 08-01": {
              name: {
                en: "Gold Cup Parade Day",
                fr: "Défilé de la Coupe d'or",
              },
            },
          },
        },
        QC: {
          name: "Quebec",
          zones: ["America/Blanc-Sablon", "America/Toronto", "America/Nipigon"],
          days: {
            "easter -2": {
              _name: "easter -2",
              note: "Either Good Friday or Easter Monday, at the employer’s option",
            },
            "monday before 05-25": {
              name: {
                en: "National Patriots' Day",
                fr: "Journée nationale des patriotes",
              },
            },
            "06-24": {
              name: {
                en: "National Holiday",
                fr: "Fête nationale du Québec",
              },
            },
            // Civic Holiday
            "monday after 08-01": false,
            // National Day for Truth and Reconciliation
            "09-30 since 2021": false,
            // Remembrance Day
            "11-11": false,
          },
        },
        SK: {
          name: "Saskatchewan",
          zones: [
            "America/Regina",
            "America/Swift_Current",
            "America/Edmonton",
          ],
          days: {
            "3rd monday after 02-01": {
              name: {
                en: "Family Day",
                fr: "Fête de la famille",
              },
            },
            "3rd monday after 08-01": {
              name: {
                en: "Saskatchewan Day",
              },
            },
            // National Day for Truth and Reconciliation
            "09-30 since 2021": false,
          },
        },
        YT: {
          name: "Yukon",
          zones: ["America/Whitehorse", "America/Dawson"],
          days: {
            "3rd monday after 08-01": {
              name: {
                en: "Discovery Day",
                fr: "Jour de la Découverte",
              },
            },
          },
        },
      },
    },
  },
  names: {
    "01-01": {
      name: {
        en: "New Year's Day",
        fr: "Nouvel An",
      },
    },
    "01-02": {
      name: {
        en: "Day after New Year's",
        fr: "Jour après le Nouvel An",
      },
    },
    "05-01": {
      name: {
        en: "Labour Day",
        fr: "Fête du travail",
      },
    },
    "12-24": {
      name: {
        en: "Christmas Eve",
        fr: "Veille de Noël",
      },
    },
    "12-25": {
      name: {
        en: "Christmas Day",
        fr: "Noël",
      },
    },
    "12-26": {
      name: {
        en: "Boxing Day",
        fr: "Lendemain de Noël",
      },
    },
    "12-31": {
      name: {
        en: "New Year's Eve",
        fr: "Saint-Sylvestre",
      },
    },
    "easter -2": {
      name: {
        en: "Good Friday",
        fr: "Vendredi saint",
      },
    },
    "easter -1": {
      name: {
        en: "Easter Saturday",
        fr: "Samedi saint",
      },
    },
    easter: {
      name: {
        en: "Easter Sunday",
        fr: "Pâques",
      },
    },
  },
};

export default holidays;
