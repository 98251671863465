import { useCallback, useMemo, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { datadogRum } from "@datadog/browser-rum";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTenantFlags } from "@nestoca/multi-tenant";
import {
  Box,
  Button,
  Divider,
  Flex,
  Typography,
  HStack,
  Select,
  FormElement,
  Tooltip,
} from "@nestoca/ui";
import { useAnalytics } from "@shared/analytics";
import { useGetAccount, useMutateAccount } from "@shared/api/hooks/account";
import { useGetApplicationsByApplicantId } from "@shared/api/hooks/applications";
import {
  TOAST_AUTOCLOSE_DELAY_IN_MS,
  ApplicationState,
  LANGUAGE_OPTIONS,
} from "@shared/constants";
import { useTranslatedOptions } from "@shared/ui/hooks/use-translated-options";
import Debug from "debug";
import { useRouter } from "next/router";
import { FormProvider, useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { BsFillArrowLeftCircleFill, BsPencilSquare } from "react-icons/bs";
import { toast } from "react-toastify";

import styles from "../burger-menu.module.scss";
import { CommunicationPreferences } from "../communication-preferences/communication-preferences";

import { SettingsFormData, schema } from "./schema";
import { UserInfoField } from "./settings";
import { UserDetailsEditable } from "./user-details-editable";

import type { BurgerMenuPageType } from "../burger-menu";

const debug = Debug("nesto:user-settings");

export const SettingsEditable = ({
  setMenuPage,
  personalInfo,
  contactDetails,
  setMenuOpen,
}: {
  setMenuPage: (menuPage: BurgerMenuPageType) => void;
  setMenuOpen: (menuOpen: boolean) => void;
  personalInfo: UserInfoField[];
  contactDetails: UserInfoField[];
}) => {
  const { track } = useAnalytics();
  const { enableDeleteAccount } = useTenantFlags();
  const { data: applications } = useGetApplicationsByApplicantId();
  const { data: account, isLoading } = useGetAccount();

  const { t } = useTranslation("common");
  const { logout } = useAuth0();
  const [edit, setEdit] = useState(false);

  const { push, asPath } = useRouter();
  const accountMutation = useMutateAccount();
  const languageOptions = useTranslatedOptions(LANGUAGE_OPTIONS);

  const formData = useMemo(
    () =>
      ({
        firstName: account?.firstName || "",
        lastName: account?.lastName || "",
        phone: account?.phone || "",
        email: account?.email || "",
        preferredLanguage: account?.preferredLanguage || "en",
      }) as SettingsFormData,
    [account]
  );

  const selectedLanguage = useMemo(() => {
    return account?.preferredLanguage === "en"
      ? t("language.english", { ns: "common" })
      : t("language.french", { ns: "common" });
  }, [account, t]);

  const isDeleteAccountDisabled = useMemo(() => {
    const appInProgress = applications?.find((application) => {
      return ![ApplicationState.Created, ApplicationState.Closed].includes(
        application.applicationState
      );
    });
    return !!appInProgress;
  }, [applications]);

  const methods = useForm<SettingsFormData>({
    reValidateMode: "onChange",
    resolver: zodResolver(schema),
    defaultValues: formData,
  });

  const resetValues = useCallback(
    (values: SettingsFormData) => {
      methods.reset(values);
    },
    [methods]
  );

  const onSubmit = useCallback(
    async (formData: SettingsFormData) => {
      if (!account) {
        return toast(t("failedToSave", { ns: "error" }), { type: "error" });
      }

      if (methods.formState.isDirty) {
        accountMutation.mutate(
          {
            ...account,
            ...formData,
            phoneSpecified: !!formData.phone,
          },
          {
            onSuccess: () => {
              debug("onSubmit onSuccess");
              resetValues(formData);

              toast(t("dataUpdatedSuccess", { ns: "applications" }), {
                type: "success",
                autoClose: TOAST_AUTOCLOSE_DELAY_IN_MS,
              });

              setEdit(false);

              push(asPath, asPath, {
                locale: formData.preferredLanguage,
              });
            },
            onError: (error) => {
              debug("onSubmit onError", error);
              toast(t("dataUpdatedFailure", { ns: "applications" }), {
                type: "error",
              });
              datadogRum.addError(error);
              setEdit(false);
            },
          }
        );
      }

      return formData;
    },
    [
      account,
      accountMutation,
      t,
      resetValues,
      methods.formState.isDirty,
      push,
      asPath,
    ]
  );

  if (!account || isLoading) {
    return null;
  }

  return (
    <>
      <FormProvider {...methods}>
        <Box as="form" onSubmit={methods.handleSubmit(onSubmit)}>
          <Flex direction="column" align="start">
            <Button
              size="large"
              variant="ghost"
              onClick={() => setMenuPage("HOME")}
              className={styles["back-button"]}
              aria-label="back to home burger menu"
            >
              <BsFillArrowLeftCircleFill size={24} />
              <Typography size="00" weight={7}>
                {t("back", { ns: "form" })}
              </Typography>
            </Button>
            <Typography as="h1" size={4} weight={6} className={styles.header}>
              {edit ? t("editSettings") : t("settings")}
            </Typography>
            <Flex direction="column" className={styles["settings__user-info"]}>
              <Box className={styles["settings__user-details"]}>
                <HStack gap={6}>
                  <Typography size={1} weight={7}>
                    {t("personalInfo")}
                  </Typography>
                  {!edit && (
                    <Box className={styles["edit"]}>
                      <Flex
                        gap={2}
                        align="center"
                        onClick={() => setEdit(!edit)}
                      >
                        <Typography size={1} weight={4} color="blue">
                          {t("edit")}
                        </Typography>
                        <BsPencilSquare
                          size="24"
                          color="var(--color-blue-500)"
                        />
                      </Flex>
                    </Box>
                  )}
                </HStack>
                {personalInfo.map((field) => (
                  <UserDetailsEditable
                    key={field.id}
                    edit={edit}
                    field={field}
                  />
                ))}
              </Box>
              <Box className={styles["settings__user-details"]}>
                <Typography size={1} weight={7}>
                  {t("contactDetails")}
                </Typography>
                {contactDetails.map((field) => (
                  <UserDetailsEditable
                    key={field.id}
                    edit={edit}
                    field={field}
                  />
                ))}
              </Box>
            </Flex>
          </Flex>
          {!edit ? (
            <Flex direction="column" className={styles["settings__language"]}>
              <Typography size="00" weight={5}>
                {t("preferredLanguage.label")}
              </Typography>
              <Typography
                size={0}
                weight={7}
                className={styles["settings__language--selected"]}
              >
                {selectedLanguage}
              </Typography>
            </Flex>
          ) : (
            <FormElement>
              <Controller
                control={methods.control}
                name="preferredLanguage"
                render={({ field }) => (
                  <Select
                    {...field}
                    value={field.value ?? ""}
                    placeholder={t("preferredLanguage.label")}
                    options={languageOptions}
                  />
                )}
              />
            </FormElement>
          )}
          <Divider />
          {edit && (
            <Flex direction="column" justify="center" grow={1}>
              <Flex
                direction="column"
                gap={2}
                className={styles["settings__buttons"]}
              >
                <Button
                  data-testid="submit"
                  variant="primary"
                  name="button"
                  type="submit"
                >
                  {t("saveSettings")}
                </Button>
                <Button
                  variant="secondary"
                  name="button"
                  onClick={() => {
                    methods.reset();
                    setEdit(false);
                  }}
                >
                  {t("cancel")}
                </Button>
              </Flex>
            </Flex>
          )}
        </Box>
      </FormProvider>
      {!edit && (
        <>
          <CommunicationPreferences showTitle={true} />
          <Flex direction="column" justify="center" grow={1} gap={3}>
            <Button
              variant="secondary"
              className={styles["burger-menu--signOut"]}
              onClick={() => {
                track({ event: "logout" });
                logout({
                  logoutParams: {
                    /**
                     * TODO: This is temporary for a hotfix to prod. We need to figure out a better way to customize this.
                     * Maybe an env variable?
                     */
                    returnTo: `${window.location.origin}/`,
                    federated: true,
                  },
                });
              }}
            >
              {t("signOut")}
            </Button>
            {enableDeleteAccount && (
              <>
                <Button
                  variant="primary"
                  disabled={isDeleteAccountDisabled}
                  onClick={() => {
                    push(`${window.location.origin}/delete-account`).then(
                      () => {
                        setMenuOpen(false);
                      }
                    );
                  }}
                  data-tooltip-id={"delete-account"}
                  className={styles["delete-button"]}
                >
                  {t("accountDeletion.button")}
                </Button>
                {isDeleteAccountDisabled && (
                  <Tooltip
                    id={"delete-account"}
                    className={styles["delete-button__tooltip"]}
                  >
                    <Flex direction="column">
                      <Typography size={0} weight={7}>
                        {t("accountDeletion.feedback.applicationInProgress")}
                      </Typography>
                      <Typography
                        weight={4}
                        className={styles["delete-button__tooltip-text"]}
                      >
                        {t("accountDeletion.feedback.applicationInProgress1")}
                      </Typography>
                    </Flex>
                  </Tooltip>
                )}
              </>
            )}
          </Flex>
        </>
      )}
    </>
  );
};
