import { forwardRef } from "react";
import type { ReactNode } from "react";

import { RadioGroup } from "@nestoca/ui";
import clsx from "clsx";

import {
  ChoiceOption as ChoiceOptionComp,
  ChoiceOptionProps,
} from "./choice-option";
import styles from "./choice.module.scss";

import type { UseRadioProps, RadioGroupProps } from "@nestoca/ui";
import type { ControllerProps, ControllerRenderProps } from "react-hook-form";

type ChoiceColumnProps = {
  direction?: "column";
  itemGrow?: false;
};

type ChoiceRowProps = {
  direction?: "row";
  itemGrow?: boolean;
};

interface BaseChoiceProps
  extends Omit<RadioGroupProps, "onChange" | "value" | "defaultValue"> {
  options: ChoiceOption[];
  asBoolean?: boolean;
}

type ChoiceProps = BaseChoiceProps &
  Omit<ControllerProps, "render"> &
  Omit<ControllerRenderProps, "ref"> &
  (ChoiceColumnProps | ChoiceRowProps);

interface ChoiceOption
  extends Omit<UseRadioProps, "value">,
    Omit<ChoiceOptionProps, "value"> {
  label: ReactNode;
  value?: ChoiceOptionProps["value"];
}

export const Choice = forwardRef<HTMLInputElement, ChoiceProps>(
  (
    {
      name,
      options,
      asBoolean = false,
      onChange,
      direction = "column",
      itemGrow,
      className,
      ...rest
    },
    ref
  ) => {
    const onChangeHandler = (nextValue: unknown) => {
      let value = nextValue;
      if (asBoolean) {
        value = nextValue === "true" ? true : false;
      }

      onChange?.(value);
    };

    return (
      <RadioGroup
        ref={ref}
        name={name}
        direction={direction}
        gap={3}
        style={{ width: "auto" }}
        {...rest}
        onChange={onChangeHandler}
        className={clsx(
          styles.choice,
          {
            [styles["choice__item--grow"]]: itemGrow,
          },
          className
        )}
      >
        {options.map(({ label, ...radioProps }) => (
          <ChoiceOptionComp
            key={`${name}-${radioProps.value}`}
            variant="secondary"
            data-dd-action-name={radioProps.value}
            {...radioProps}
          >
            {label}
          </ChoiceOptionComp>
        ))}
      </RadioGroup>
    );
  }
);
