import { Typography, Flex } from "@nestoca/ui";

export const ConfirmationToastMessage = ({
  title,
  text,
}: {
  title: string;
  text: string;
}) => (
  <Flex direction="column" gap={1}>
    <Typography size={1} weight={7} textColor="var(--color-foreground-default)">
      {title}
    </Typography>
    <Typography size={0}>{text}</Typography>
  </Flex>
);
