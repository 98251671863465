import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  ButtonIcon,
  Divider,
  Flex,
  Typography,
  Button,
} from "@nestoca/ui";
import clsx from "clsx";
import Link from "next/link";
import { useTranslation } from "react-i18next";
import { MdClose } from "react-icons/md";

import { NavbarLogo } from "../navbar-logo";

import styles from "./burger-menu.module.scss";
import { LanguageSelector } from "./language-selector";

export const BurgerMenuGAQ = ({
  setMenuOpen,
}: {
  setMenuOpen: (menuOpen: boolean) => void;
}) => {
  const { t } = useTranslation("common");
  const { isAuthenticated } = useAuth0();

  const links = ["howItWorks", "career", "termsOfService", "privacyPolicy"];

  return (
    <Box
      as="nav"
      className={clsx(styles["burger-menu"], styles.GAQ)}
      data-testid="burger-menu"
    >
      <Flex
        className={styles["burger-menu__header"]}
        direction="row"
        align="center"
        justify="between"
      >
        <NavbarLogo />
        <ButtonIcon
          variant="ghost"
          icon={<MdClose size={26} />}
          aria-label="close burger menu"
          onClick={() => setMenuOpen(false)}
          size="medium"
          data-testid="close-burger-menu"
          data-dd-action-name="close burger menu"
          className={styles["burger-menu__close-button"]}
          isRound={true}
        />
      </Flex>
      <Flex direction="column" className={styles["burger-menu__content"]}>
        <Divider />
        {links.map((link) => (
          <NavLink key={link} id={link} />
        ))}
        <LanguageSelector changePreferredLanguage={false} />
        <Divider />
        <Flex direction="column" justify="center" grow={1} className={styles["burger-menu__cta"]}>
          {!isAuthenticated && (
            <Button
              as="a"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore - polymorphic prop not recognized by typescript
              href="/"
            >
              {t("login")}
            </Button>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

export const NavLink = ({ id }: { id: string }) => {
  const { t } = useTranslation("common");

  return (
    <>
      <Link
        href={t(`links.${id}.link`)}
        target="_blank"
        className={styles["burger-menu__link-wrapper"]}
      >
        <Typography weight={5} size={0}>
          {t(`links.${id}.label`)}
        </Typography>
      </Link>
      <Divider />
    </>
  );
};
