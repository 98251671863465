import { MINIMUM_NEW_FINANCING_REQUIRED } from "@shared/constants";

import type { CallbackFn, ChartMachine } from "@hooks/use-wizard";
import type { FormNewMortgage } from "@shared/constants/schema";

export type NewMortgageChartContext = Partial<FormNewMortgage> & {
  isAuthenticated: boolean;
};

export const minAmountNewCallback: CallbackFn<NewMortgageChartContext> = (
  downPaymentAmount,
  { context }
) => {
  const propertyValue = context?.propertyValue || 0;

  if (propertyValue - downPaymentAmount < MINIMUM_NEW_FINANCING_REQUIRED) {
    return "loan-partner";
  }

  return "timing";
};

export const newMortgageChart = {
  INITIAL: "owner-occupied",
  "owner-occupied": "property-type",
  "property-type": {
    COMMERCIAL: "loan-partner",
    "*": "property-value",
  },
  "property-value": "downpayment",
  downpayment: minAmountNewCallback,
  timing: (value: string, { context }) => {
    const isAuthenticated = context?.isAuthenticated;

    if (isAuthenticated) {
      return "rates";
    }

    return "contact";
  },
  contact: "rates",
  rates: { type: "final" },
} satisfies ChartMachine<NewMortgageChartContext>;

// Because JS cannot garantee the order of keys, we need to do this
export const newMortgageCounterKeys = [
  "owner-occupied",
  "property-type",
  "property-value",
  "downpayment",
  "timing",
] as const;

export const newMortgageKeys = [
  ...newMortgageCounterKeys,
  "contact",
  "rates",
] as const;

export type NewMortgageCouonterStep = (typeof newMortgageCounterKeys)[number];
export type NewMortgageStep = Exclude<keyof typeof newMortgageChart, "INITIAL">;
