import {
  DownPaymentRatioEnum,
  MAX_LENDING_AMOUNT_RATIO,
  MINIMUM_REFI_FINANCING_REQUIRED,
} from "@shared/constants";

import { getAsNumber } from "./get-as-number";
import { isNullOrUndefined } from "./is-null-or-undefined";

export const getDownPaymentPercentage = (
  propertyValue?: number,
  downPayment?: number,
  roundResult = true
): number => {
  if (!propertyValue || !downPayment) return 0;
  const percentage = (downPayment / propertyValue) * 100;
  return roundResult ? Math.round(percentage * 100) / 100 : percentage;
};

export const getDownPaymentValue = (
  propertyValue: number,
  downPaymentPercentage: number
): number => {
  if (!propertyValue || !downPaymentPercentage) return 0;

  const downPayment = (downPaymentPercentage * propertyValue) / 100;

  return downPayment;
};

/**
 * See Gouverment of Canada down payment rules
 * @ref https://www.canada.ca/en/financial-consumer-agency/services/mortgages/down-payment.html#toc0
 * */
// Minimum amount of down payment 5% of the purchase price
export const MIN_DOWNPAYMENT_OWN_PERCENT = 5;
// 5% of the first $500,000, which is equal to $25,000
export const BASE_DOWN_PAYMENT_REMAINDER = 25_000;
// 10% for the portion of the purchase price above $500,000
export const REMAINDER_PERCENTAGE = 0.1;
export const HALF_MILLION_DOWN_PAYMENT_TRIGGER = 500_000;
export const ONE_MILLION_DOWN_PAYMENT_TRIGGER = 1_000_000;
// $1 million or more - Minimum amount of down payment 20% of the purchase price
// purpose is RENTAL
// *** NOTE: OWNER_OCCUPIED_AND_RENTAL is not 20% but the 5% + 10% remainder rule ***
export const MIN_DOWNPAYMENT_RENTAL_MILLION_PERCENT = 20;

export const getMinimumDownPayment = (
  propertyValue: number,
  isRental: boolean
) => {
  const isOneMillionAndMore = propertyValue >= ONE_MILLION_DOWN_PAYMENT_TRIGGER;
  const isBetween500kAnd1Million =
    propertyValue >= HALF_MILLION_DOWN_PAYMENT_TRIGGER &&
    propertyValue < ONE_MILLION_DOWN_PAYMENT_TRIGGER;

  let minDownPaymentPercentage = MIN_DOWNPAYMENT_OWN_PERCENT;

  // 100% Rental min DP is 20%
  // 1 Million plus
  if (isRental || isOneMillionAndMore) {
    minDownPaymentPercentage = MIN_DOWNPAYMENT_RENTAL_MILLION_PERCENT;
  }

  // 5% of the first $500,000 of the purchase price
  // 10% for the portion of the purchase price above $500,000
  if (isBetween500kAnd1Million && !isRental) {
    const downPaymentRemainder =
      (propertyValue - HALF_MILLION_DOWN_PAYMENT_TRIGGER) *
      REMAINDER_PERCENTAGE;

    return BASE_DOWN_PAYMENT_REMAINDER + downPaymentRemainder;
  }

  // less than 500k
  const minDownPaymentAmount = (minDownPaymentPercentage * propertyValue) / 100;

  return minDownPaymentAmount;
};

/**
 * Validates that the total downpayment amount is sufficient based
 * on the value of the property and its rental status.
 *
 * For rental properties, the downpayment must be at least 20% of the property value.
 *
 * For properties valued at more than $1,000,000, the downpayment must be at least 20% of the property value.
 *
 * For properties valued between $500,000 and $1,000,000, the downpayment must be at least
 * 5% of the first $500,000 of the property value AND 10% of the portion of the property value above $500,000.
 *
 * For properties valued below $500,000, the downpayment must be at least 5% of the property value.
 *
 * @param propertyValue the value of the property
 * @param downPayment the total downpayment amount
 * @param isRentalProperty whether the property is for rental or not
 */
export const isViableDownPayment = (
  propertyValue: number,
  downPayment: number,
  isRentalProperty: boolean
): boolean => {
  if (!propertyValue || !downPayment) return false;

  const minimumDownPayment = getMinimumDownPayment(
    propertyValue,
    isRentalProperty
  );

  return downPayment >= minimumDownPayment;
};

export const newMortgageDownPaymentInformation = (
  propertyValue?: number,
  downPayment?: number,
  isRentalProperty?: boolean
) => {
  if (!propertyValue) propertyValue = 0;
  if (!downPayment) downPayment = 0;

  const downPaymentRemainder = (propertyValue - 500_000) * 0.1;
  const downPaymentPercentage = getDownPaymentPercentage(
    propertyValue,
    downPayment
  );

  if (isRentalProperty && downPaymentPercentage < 20) {
    return "error:downpaymentWarnRental";
  }

  // 1 mil plus
  if (propertyValue >= 1_000_000 && downPaymentPercentage < 20) {
    return "error:downpaymentWarnMillionPlus";
  }

  // less than 500k
  if (propertyValue <= 500_000 && downPaymentPercentage < 5) {
    return "error:downpaymentWarnMin5Percent";
  }

  // more than 500k
  if (25_000 + downPaymentRemainder > downPayment) {
    return "error:downpaymentWarnInsuranceCostsSpecial";
  }

  return undefined;
};

// Refinance and Renewal
type GetAvailableWithdrawalAmountParams = {
  propertyValue: number;
  mortgageBalance: string | number;
};

export const getAvailableWithdrawalAmount = ({
  propertyValue,
  mortgageBalance,
}: GetAvailableWithdrawalAmountParams) => {
  const percentage = MAX_LENDING_AMOUNT_RATIO;
  let balance = mortgageBalance;

  if (typeof mortgageBalance === "string") {
    balance = getAsNumber(mortgageBalance) ?? 0;
  }

  const availableAmount = propertyValue * percentage - (balance as number);

  return availableAmount > 0 ? Math.round(availableAmount) : 0;
};

// Refinance
export const validateMaxRefinanceAmount = ({
  mortgageBalance,
  additionalAmount,
  propertyValue,
  isOwnerOccupied,
}: {
  mortgageBalance: number;
  additionalAmount: number;
  propertyValue: number;
  isOwnerOccupied: boolean;
}) => {
  if (isNullOrUndefined(mortgageBalance) || isNullOrUndefined(propertyValue)) {
    return false;
  }

  const refinancePercentage =
    ((mortgageBalance + additionalAmount) / propertyValue) * 100;

  if (!refinancePercentage) return false;

  if (isOwnerOccupied && refinancePercentage > 80) return false;

  if (!isOwnerOccupied && refinancePercentage > 75) return false;

  return true;
};

export const validateMinRefinanceAmount = (
  mortgageBalance: number,
  additionalFundAmount: number
) =>
  additionalFundAmount
    ? mortgageBalance + additionalFundAmount >= MINIMUM_REFI_FINANCING_REQUIRED
    : false;

// TODO: Renewal
export const validateMinRenewalAmount = (mortgageBalance: number) =>
  mortgageBalance >= MINIMUM_REFI_FINANCING_REQUIRED;

export const validateMaxRenewalAmount = (
  propertyValue: number,
  mortgageBalance: number
) => {
  if (!mortgageBalance || !propertyValue) return false;

  return mortgageBalance <= propertyValue;
};

export const validateUnderwater80 = (
  downPaymentRatio: DownPaymentRatioEnum,
  propertyValue: number,
  mortgageAmount: number
) =>
  downPaymentRatio === DownPaymentRatioEnum.TWENTY_MORE &&
  mortgageAmount / propertyValue > 0.8;

export const validateUnderwater95 = (
  propertyValue: number,
  mortgageAmount: number
) => mortgageAmount / propertyValue > 0.95;
