import { useAuth0 } from "@auth0/auth0-react";
import { useGate } from "@nestoca/gate-react";
import { useTenant } from "@nestoca/multi-tenant";
import {
  Button,
  ButtonIcon,
  Flex,
  OverflowMenu,
  Tooltip,
  Typography,
} from "@nestoca/ui";
import { useGetAccount } from "@shared/api/hooks/account";
import { isSidebarOpen as isSidebarOpenAtom } from "@shared/store";
import clsx from "clsx";
import { useAtom } from "jotai";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import {
  BsArrowsAngleContract,
  BsArrowsAngleExpand,
  BsGlobe,
  BsQuestionOctagon,
  BsShieldLock,
} from "react-icons/bs";
import { MdLogout } from "react-icons/md";

import { InitialsAvatar } from "./initials-avatar";
import styles from "./sidebar.module.scss";
import { SidebarMenuOption } from "./type";

export const Footer = () => {
  const tenant = useTenant();
  const { t } = useTranslation("common");
  const { locale, asPath } = useRouter();
  const { data: account } = useGetAccount();

  const { isAuthenticated, logout } = useAuth0();

  const [isSidebarOpen, setIsSidebarOpen] = useAtom(isSidebarOpenAtom);

  const gate = useGate();
  const isExternalBroker = gate.getRole && gate.getRole() === "externalbroker";

  const handleLogout = () => {
    logout({
      clientId: tenant?.auth0.clientId,
      logoutParams: {
        returnTo: isExternalBroker
          ? tenant?.auth0.redirectUri
          : `${tenant?.auth0.redirectUri}/logout`,
        federated: true,
      },
    });
  };

  const handleToggleSidebarStatus = () => {
    setIsSidebarOpen((currentValue) => !currentValue);
  };

  const fullOptions: SidebarMenuOption[] = [
    {
      id: "language",
      value: t("language.update", { locale: locale === "en" ? "FR" : "EN" }),
      icon: <BsGlobe size={16} />,
      href: asPath,
      locale: locale === "en" ? "fr" : "en",
    },
    {
      id: "privacyPolicy",
      value: t("sidebar.privacyPolicy"),
      icon: <BsShieldLock size={16} />,
      href:
        locale === "en"
          ? tenant?.privacyPolicyURL || ""
          : tenant?.privacyPolicyFRURL || "",
    },
    {
      id: "faq",
      value: "FAQ",
      icon: <BsQuestionOctagon size={16} />,
      href:
        locale === "en"
          ? "https://sites.google.com/nesto.ca/faqsbrokerchannel/english"
          : "https://sites.google.com/nesto.ca/faqsbrokerchannel/fran%C3%A7ais",
    },
  ];
  const brokerOptions = fullOptions.filter((option) => option.id !== "faq");
  const options = isExternalBroker ? fullOptions : brokerOptions;

  const option = (option: SidebarMenuOption) => (
    <Link
      href={option.href}
      locale={option.locale}
      data-dd-action-name={option.id}
    >
      <Flex gap={2} className={styles["menu__item"]} align="center">
        {option.icon}
        {option.value}
      </Flex>
    </Link>
  );

  const button = () => <InitialsAvatar />;
  const getOptionKey = (menuOption: SidebarMenuOption) => menuOption.value;

  return (
    <Flex
      className={styles.footer}
      direction="column"
      align={isSidebarOpen ? "stretch" : "center"}
      gap={3}
    >
      <Flex
        direction="column"
        gap={3}
        className={clsx(styles["name-block"], {
          [styles["name-block__closed"]]: !isSidebarOpen,
        })}
      >
        <OverflowMenu
          options={options}
          renderOption={option}
          button={button}
          getOptionKey={getOptionKey}
          className={styles.menu}
          withIcons
        />
        {isSidebarOpen && (
          <Typography size={0} weight={7}>
            {!!account && `${account?.firstName} ${account?.lastName}`}
          </Typography>
        )}
      </Flex>
      <Flex
        direction={isSidebarOpen ? "row" : "column"}
        gap={isSidebarOpen ? 2 : 3}
        justify="between"
        align="center"
      >
        <Button
          leftIcon={<MdLogout key="icon-logout" size={16} />}
          variant="blank"
          className={styles.logout}
          isActive={isAuthenticated}
          onClick={handleLogout}
          data-tooltip-id={"logout"}
          data-dd-action-name={"logout"}
        >
          {isSidebarOpen && t("sidebar.logout")}
        </Button>
        {!isSidebarOpen && (
          <Tooltip
            id={"logout"}
            direction="right"
            className={styles["action--tooltip"]}
            title={t("sidebar.logout")}
          />
        )}
        <ButtonIcon
          className={styles.expand}
          aria-label={isSidebarOpen ? "collapse" : "expand"}
          size="small"
          variant="ghost"
          icon={
            isSidebarOpen ? (
              <BsArrowsAngleContract key="icon-collapse" size={16} />
            ) : (
              <BsArrowsAngleExpand key="icon-expand" size={16} />
            )
          }
          onClick={handleToggleSidebarStatus}
          data-tooltip-id={isSidebarOpen ? "collapse" : "expand"}
          data-dd-action-name={isSidebarOpen ? "collapse" : "expand"}
        />
        <Tooltip
          id={isSidebarOpen ? "collapse" : "expand"}
          direction="right"
          className={styles["action--tooltip"]}
          title={isSidebarOpen ? t("sidebar.collapse") : t("sidebar.expand")}
        />
      </Flex>
    </Flex>
  );
};
