export enum ProductTermEnum {
  SIX_MONTHS = "6_MONTHS",
  ONE_YEAR = "1_YEAR",
  TWO_YEAR = "2_YEAR",
  THREE_YEAR = "3_YEAR",
  FOUR_YEAR = "4_YEAR",
  FIVE_YEAR = "5_YEAR",
  SIX_YEAR = "6_YEAR",
  SEVEN_YEAR = "7_YEAR",
  TEN_YEAR = "10_YEAR",
}

export enum PropertyTypeEnum {
  HOME_OR_CONDO = "HOME_OR_CONDO",
  SECOND_HOME = "SECOND_HOME",
  // This duplicate is intentional, and exists because in the back-end
  // 'SECOND_HOME_OR_COTTAGE' is different than 'SECONDARY_HOME_OR_COTTAGE'
  // in some parts of the application
  SECOND_HOME_OR_COTTAGE = "SECOND_HOME_OR_COTTAGE",
  SECONDARY_HOME_OR_COTTAGE = "SECONDARY_HOME_OR_COTTAGE",
  DUPLEX = "DUPLEX",
  TRIPLEX = "TRIPLEX",
  FOURPLEX = "FOURPLEX",
  RENTAL = "RENTAL",
  OTHER = "OTHER",
  COTTAGE = "COTTAGE",
}
