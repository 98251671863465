import Holidays from "date-holidays-parser";
import dayjs, { BusinessHoursMap } from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import dayjsBusinessTime from "dayjs-business-time-advanced";

import data from "./holidays";

// Attach dayjs plugin
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);
dayjs.extend(dayjsBusinessTime);

// nesto Business Week definition
export const businessTimes: BusinessHoursMap = {
  monday: [{ start: "09:00:00", end: "20:00:00" }],
  tuesday: [{ start: "09:00:00", end: "20:00:00" }],
  wednesday: [{ start: "09:00:00", end: "20:00:00" }],
  thursday: [{ start: "09:00:00", end: "20:00:00" }],
  friday: [{ start: "09:00:00", end: "20:00:00" }],
  saturday: [{ start: "10:00:00", end: "18:00:00" }],
  sunday: [{ start: "10:00:00", end: "18:00:00" }],
};

// all business hours are based on the Montreal HQ timezone
// The America/Montreal time zone has been deemed obsolete. It has been replaced by America/Toronto.
const TZ = "America/Toronto";
dayjs.tz.setDefault(TZ);
// DEFAULTS nesto Opening hours
// Set Business Times in dayjs
dayjs.setBusinessTime(businessTimes);

export const useBusinessHours = () => {
  // TBD load the data from a settings flag(flag name suggestion: `business-hours`)
  /**
   * Flag setting definition
   * ```
   * type Boolean
   * Settings JSON
   * {
   *   holidays: ["2024-06-11"],
   *   businessTimes: {
   *    tuesday: [{ start: "09:00:00", end: "19:00:00" }],
   *    wednesday: null, // no business hours
   *    thursday: [{ start: "00:00:00", end: "24:00:00" }],
   *   },
   *   exceptions: {
   *     '2024-03-01' : [{start: "08:00:00", end:"12:00:00"}],
   *   }
   * }
   * ```
   */
  // dayjs.setBusinessTime(businessTimes, "replace");
  // dayjs.setBusinessTime(flag.businessTime, "replace");
  // if (flag.holidays.length > 0) {
  //   dayjs.setHolidays(flag.holidays, "replace");
  // }

  const businessTime = dayjs.getBusinessTime();
  const userTZ = dayjs.tz.guess();
  const userDate = dayjs.tz(); // date in user timezone

  const hd = new Holidays(data, "CA", "QC", {
    types: ["public", "bank"],
    timezone: userTZ,
  });

  const isHoliday = !!hd.isHoliday(userDate.toDate()) || dayjs().isHoliday();
  const holidays = hd.getHolidays();

  const businessDate = dayjs().tz(TZ, false);

  if (isHoliday) {
    return {
      businessTZ: TZ,
      userTZ,
      isHoliday: true,
      isBusinessTime: false,
      businessTime,
      holidays,
      userDate: userDate.toDate().toString(),
      businessDate: businessDate,
    };
  }

  const isBusinessTime = userDate.isBusinessTime();

  return {
    businessTZ: TZ,
    userTZ,
    isHoliday: false,
    isBusinessTime,
    businessTime,
    holidays,
    userDate: userDate.toDate().toString(),
    businessDate: businessDate,
  };
};
