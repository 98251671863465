const toAngryCase = (string: string): string =>
  string &&
  string
    .split("-")
    .map((s) => s.toUpperCase())
    .join("_");

export const getQuestionName = (questionId: string) => {
  return `GETAQUOTE_${toAngryCase(questionId)}`;
};

const mortgageTypeMap: Record<string, string> = {
  "new-mortgage": "NEW",
  refinance: "REFINANCE",
  renewal: "RENEWAL",
};

export const getMortgageTypeFromPath = (path: string) => {
  const urlElements = path.split("/");
  const mortgageType = urlElements.find((element) => {
    if (Object.keys(mortgageTypeMap).includes(element)) {
      return element;
    }
  });
  return mortgageType ? mortgageTypeMap[mortgageType] : "unknown";
};
