import { useCallback } from "react";

import { Flex, VStack } from "@nestoca/ui";
import { TOAST_AUTOCLOSE_DELAY_IN_MS } from "@shared/constants";
import { SupportInfoType, useSupportContact } from "@shared/ui";
import { ContactItem } from "@shared/ui/components/contact/contact-item";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import styles from "./advisor-contact.module.scss";

export const AdvisorContact = ({
  applicationId,
}: {
  applicationId: number;
}) => {
  const { t } = useTranslation("applications");

  const { supportContactInfo } = useSupportContact({
    applicationId,
  });

  const onClick = useCallback(
    (option: SupportInfoType) => {
      toast(t("contact.valueCopied", { data: option.type }), {
        type: "success",
        autoClose: TOAST_AUTOCLOSE_DELAY_IN_MS,
      });
    },
    [t]
  );

  return (
    <Flex direction="column" className={styles["progress-modal-advisor"]}>
      <VStack align="start">
        {supportContactInfo.map((info) => {
          return (
            <ContactItem
              key={info.id}
              className={styles["contact-item"]}
              optionItem={info}
              onClick={onClick}
            />
          );
        })}
      </VStack>
    </Flex>
  );
};
