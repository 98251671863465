import { api } from "@shared/api/client";

import type {
  Application,
  ApplicationsPayload,
  ApplicationsResponse,
  CommunicationPreferences,
  DownPayment,
  AssignedAdvisor,
  ApplicationChangeStateEvent,
  CreateApplication,
} from "@shared/constants";

export const getCommunicationPreferences = async () => {
  const { data } = await api.get<CommunicationPreferences>(
    "/account/communications/preferences"
  );
  return data;
};

export const updateCommunicationPreferences = async (
  params: CommunicationPreferences
) => {
  const { data } = await api.put<CommunicationPreferences>(
    "/account/communications/preferences",
    params
  );
  return data;
};

export const getApplication = async (id: number) => {
  const { data } = await api.get<Application>(`applications/${id}`);
  return data;
};

export const getApplicationsByApplicantId = async () => {
  const { data } = await api.get<Application[]>(`applications/byapplicantid`);
  // allways sort by created date ASC to keep a consistent order in frontend
  return data
    ? data.sort(
        (a, b) => new Date(a.created).getTime() - new Date(b.created).getTime()
      )
    : [];
};

export const getAssignedRepresentative = async (applicationId: number) => {
  const { data } = await api.get<AssignedAdvisor>(
    `applications/${applicationId}/assigned-representative`
  );

  return data;
};

export const getApplications = async (payload: ApplicationsPayload) => {
  const { data } = await api.post<ApplicationsResponse>(
    "applications",
    payload
  );

  return data;
};

export const getApplicationsExternalBroker = async (
  payload: ApplicationsPayload
) => {
  const { data } = await api.post<ApplicationsResponse>(
    "external-brokerage/applications",
    payload
  );

  return data;
};

export const getApplicationsSearchResults = async (
  payload: ApplicationsPayload
) => {
  const { data } = await api.post<ApplicationsResponse>(
    "applications/minified",
    payload
  );

  return data;
};

export const getApplicationsSearchExternalBrokerResults = async (
  payload: ApplicationsPayload
) => {
  const { data } = await api.post<ApplicationsResponse>(
    "external-brokerage/applications/minified",
    payload
  );

  return data;
};

export const getDownPayment = async (applicationId: number) => {
  const { data } = await api.get<DownPayment>(
    `applications/${applicationId}/downpayment`
  );

  return data;
};

export const updateApplicationState = async (
  applicationId: number,
  event: ApplicationChangeStateEvent
) => {
  const { data } = await api.put<Application>(
    `applications/${applicationId}/state`,
    { event }
  );

  return data;
};

export const createApplication = async (payload: CreateApplication) => {
  const { data } = await api.post<Application>("applications", {
    ...payload,
    enableMulti: true,
  });

  return data;
};
