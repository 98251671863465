import { useTranslation } from "next-i18next";

export interface OptionItem {
  value: string | number | boolean;
  label: string;
}

/**
 * Get an array of select options with translate keys for labels
 *
 * usage:
 * ```
 * const myOptions = useTranslatedOptions([
 *  { value: "1", label: "myKey" },
 *  { value: "2", label: "myOtherKey" },
 * ]);
 * ```
 *
 * @param options
 * @returns an array of option items with translated labels
 */
export const useTranslatedOptions = <TOption extends OptionItem>(
  options: TOption[]
) => {
  const { t } = useTranslation();

  return options.map((option) => ({
    ...option,
    value: option.value,
    label: t(option.label),
  }));
};
