import { useCallback } from "react";

import {
  Modal,
  Button,
  Typography,
  Flex,
  useBreakpointValue,
  Spinner,
} from "@nestoca/ui";
import { useMutateApplicationState } from "@shared/api/hooks/applications";
import { TOAST_AUTOCLOSE_DELAY_IN_MS } from "@shared/constants";
import { useTranslation, Trans } from "next-i18next";
import { BsTrash } from "react-icons/bs";
import { FaCircleCheck } from "react-icons/fa6";
import { toast } from "react-toastify";

import { ConfirmationToastMessage } from "./confirmation-toast-message";
import { DeleteApplicationModalContainer } from "./delete-application-modal-container";
import styles from "./delete-application-modal.module.scss";

export const DeleteApplicationModal = ({
  visible,
  onClose,
  applicationId,
}: {
  visible: boolean;
  onClose: () => void;
  applicationId: number;
}) => {
  const { t } = useTranslation("applications");
  const isMobile = useBreakpointValue({ default: true, md: false });
  const {
    mutate: deleteApplication,
    isLoading,
    isSuccess,
  } = useMutateApplicationState(applicationId);

  const onDelete = useCallback(() => {
    return deleteApplication("DELETE", {
      onSuccess: () => {
        !isMobile &&
          toast(
            <ConfirmationToastMessage
              title={t("deleteApplication.success")}
              text={t("deleteApplication.successDescription")}
            />,
            {
              type: "success",
              autoClose: TOAST_AUTOCLOSE_DELAY_IN_MS,
            }
          );
      },
      onError: () => {
        toast(
          <ConfirmationToastMessage
            title={t("deleteApplication.failure")}
            text={t("dataUpdatedFailure")}
          />,
          {
            type: "error",
          }
        );

        onClose();
      },
    });
  }, [deleteApplication, onClose, isMobile, t]);

  if (isMobile) {
    if (isLoading) {
      return (
        <DeleteApplicationModalContainer visible={visible} onClose={onClose}>
          <Modal.Body className={styles["delete-application-modal__body"]}>
            <Flex direction="column" gap={6} align="center">
              <Typography size={3} weight={7}>
                {t("deleteApplication.deleting")}
              </Typography>
              <Spinner />
            </Flex>
          </Modal.Body>
          <Modal.Footer className={styles["delete-application-modal__footer"]}>
            <Flex className={styles["delete-application-modal__ctas"]} gap={4}>
              <Button variant="secondary" onClick={onClose} disabled={true}>
                {t("cancel")}
              </Button>
              <Button variant="critical" onClick={onDelete} disabled={true}>
                {t("deleteApplication.mobileButton")}
                <BsTrash key="btn-trash" />
              </Button>
            </Flex>
          </Modal.Footer>
        </DeleteApplicationModalContainer>
      );
    }

    if (isSuccess) {
      return (
        <DeleteApplicationModalContainer visible={visible} onClose={onClose}>
          <Flex
            direction="column"
            gap={5}
            align="center"
            grow={1}
            justify="center"
          >
            <Flex direction="column" gap={2} align="center">
              <Typography size={1} weight={7}>
                {t("deleteApplication.success")}
              </Typography>
              <Typography size={0}>
                {t("deleteApplication.successDescription")}
              </Typography>
            </Flex>
            <FaCircleCheck
              size={84}
              color="var(--color-status-success-foreground-saturated)"
            />
          </Flex>
        </DeleteApplicationModalContainer>
      );
    }
  }

  return (
    <DeleteApplicationModalContainer visible={visible} onClose={onClose}>
      <Modal.Body className={styles["delete-application-modal__body"]}>
        <Flex direction="column" gap={2}>
          <Typography size={isMobile ? 3 : 1} weight={7}>
            {t("deleteApplication.title")}
          </Typography>
          <Typography size={0}>
            <Trans
              t={t}
              components={{ bold: <strong className={styles.underline} /> }}
              i18nKey="deleteApplication.description"
            />
          </Typography>
        </Flex>
      </Modal.Body>
      <Modal.Footer className={styles["delete-application-modal__footer"]}>
        <Flex className={styles["delete-application-modal__ctas"]} gap={4}>
          <Button variant="secondary" onClick={onClose}>
            {t("cancel")}
          </Button>
          <Button
            variant="critical"
            onClick={onDelete}
            isLoading={!isMobile && isLoading}
          >
            {isMobile
              ? t("deleteApplication.mobileButton")
              : t("deleteApplication.button")}
            <BsTrash key="btn-trash" />
          </Button>
        </Flex>
      </Modal.Footer>
    </DeleteApplicationModalContainer>
  );
};
