import { PropsWithChildren } from "react";

import { Flex, FlexProps } from "@nestoca/ui";
import clsx from "clsx";

import styles from "./card.module.scss";

export interface CardProps extends FlexProps {
  header?: React.ReactNode;
  actions?: React.ReactNode;
}

export const Card = ({
  header,
  actions,
  children,
  className,
  ...rest
}: PropsWithChildren<CardProps>) => {
  return (
    <Flex {...rest} className={clsx(styles.card, className)}>
      {header && <Flex className={styles.card__header}>{header}</Flex>}
      {children && <Flex className={styles.card__content}>{children}</Flex>}
      {actions && <Flex className={styles.card__actions}>{actions}</Flex>}
    </Flex>
  );
};
