import React, { forwardRef, PropsWithChildren } from "react";

import {
  Box,
  ButtonProps,
  callAll,
  callAllHandlers,
  Flex,
  RadioProps,
  useRadio,
  useRadioGroupContext,
  VisuallyHidden,
} from "@nestoca/ui";
import clsx from "clsx";
import { useAnimate } from "framer-motion";

import styles from "./choice.module.scss";

export type ChoiceOptionProps = Omit<
  ButtonProps,
  "backgroundColor" | "textColor" | "onChange"
> &
  RadioProps;

export const ChoiceOption = forwardRef<
  HTMLInputElement,
  PropsWithChildren<ChoiceOptionProps>
>(({ children, className, style, onClick, ...rest }, ref) => {
  const group = useRadioGroupContext();
  const [scope, animate] = useAnimate();

  const {
    onChange: onChangeProp,
    value: valueProp,
    isDisabled = group?.isDisabled,
    isFocusable = group?.isFocusable,
    inputProps: htmlInputProps,
  } = rest;

  let isChecked = rest.isChecked;
  if (group?.value != null && valueProp != null) {
    isChecked = group.value === valueProp;
  }

  let onChange = onChangeProp;
  if (group?.onChange && valueProp != null) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    onChange = callAll(group.onChange, onChangeProp);
  }

  const name = rest?.name ?? group?.name;

  const { getInputProps, getCheckboxProps } = useRadio({
    ...rest,
    isChecked,
    isFocusable,
    isDisabled,
    onChange,
    name,
  });

  // Other props passed to `div` role button element
  const otherProps = {
    onClick,
  };

  const checkboxProps = getCheckboxProps(otherProps, scope);
  // remove `aria-hidden` from the buttonProps
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { ["aria-hidden"]: _, ...buttonProps } = checkboxProps;

  const inputProps = getInputProps(htmlInputProps, ref);

  const onSelectItem = async () => {
    // @ts-expect-error - `inputProps.disabled` is a boolean
    // and it exists in the `inputProps` object
    // seems like it's missing in the getInputProps typings
    if (inputProps.disabled) {
      return;
    }

    await animate(
      scope?.current,
      {
        scale: [0.9, 1.25, 1],
      },
      { duration: 0.25, ease: "easeInOut" }
    );
  };

  const onClickHandler = callAllHandlers(onSelectItem, buttonProps.onClick);

  return (
    <Flex as="label">
      <VisuallyHidden aria-hidden>
        <input {...inputProps} tabIndex={-1} />
      </VisuallyHidden>
      <Flex
        tabIndex={0}
        role="button"
        htmlFor={inputProps.id}
        // ref={scope} // `scope` is the ref included in `buttonProps`
        {...buttonProps}
        onClick={onClickHandler}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        data-isactive={inputProps?.checked?.toString()}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        aria-pressed={inputProps?.checked?.toString()}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        disabled={inputProps.disabled}
        className={clsx(styles.choice__option, className)}
        style={style}
      >
        <Box className={styles.truncate}>{children}</Box>
      </Flex>
    </Flex>
  );
});
