import { RegisteredAddress } from "@shared/constants/application/register-address";
import {
  differenceInCalendarDays,
  endOfDay,
  isBefore,
  startOfDay,
  subYears,
  format,
  parseISO,
} from "date-fns";

export const isBeforeDefinedDate =
  (dateToCompare: number | Date) =>
  /**
   *
   * @param dateString date string in the format of YYYY-MM-DD
   * @returns
   */
  (dateString: string) => {
    if (typeof dateString !== "string") {
      return false;
    }

    // User entered value as start of the day
    // parsing of date strings with the Date constructor
    // is strongly discouraged due to browser differences and inconsistencies.
    // date-only strings (e.g. "1970-01-01") are treated as UTC, not local.
    const [year, month, day] = dateString.split("-");
    const valueAsDate = new Date(+year, +month - 1, +day, 0, 0, 0, 0);

    const compareDate = endOfDay(dateToCompare);

    return isBefore(valueAsDate, compareDate);
  };

export const atLeast18 = isBeforeDefinedDate(subYears(Date.now(), 18));

export function getLastThreeYears() {
  const currentYear = new Date().getFullYear();
  const years = [currentYear - 2, currentYear - 1, currentYear];

  return years.map((year) => {
    return { value: year.toString(), label: year.toString() };
  });
}

// Get the current date in YYYY-MM-DD format
export const currentDate = new Date().toISOString().split("T")[0];

export const addTenYears = () => {
  const currentDate = new Date();
  currentDate.setFullYear(currentDate.getFullYear() + 10);

  return currentDate.toISOString().split("T")[0];
};

export const dateExceedsOneYear =
  (startDate: number | Date) => (value: string | Date) => {
    if (typeof value !== "string") {
      return undefined;
    }

    // User entered value as start of the day
    // parsing of date strings with the Date constructor
    // is strongly discouraged due to browser differences and inconsistencies.
    // date-only strings (e.g. "1970-01-01") are treated as UTC, not local.
    const [year, month, day] = value.split("-");
    const valueAsDate = startOfDay(new Date(+year, +month - 1, +day));

    return Math.abs(differenceInCalendarDays(startDate, valueAsDate)) <= 365;
  };

export const dateIsFuture = (value: string) => {
  return dateIsFutureOf(new Date())(value);
};

export const dateIsFutureOf = (now: Date) => (value: string) => {
  const [year, month, day] = value.split("-");
  const valueAsDate = new Date(+year, +month - 1, +day, 0, 0, 0, 0);

  // Set as start of the day
  const today = new Date(now.setHours(0, 0, 0, 0));

  return valueAsDate >= today;
};

export const isValidYear = (value: number) => {
  const currentYear = new Date().getFullYear();
  return value.toString().length === 4 && value > 1500 && value <= currentYear;
};

export const getSumYearsMonths = (addresses: RegisteredAddress[]) => {
  const years = addresses
    ? addresses.reduce((acc, { occupiedYears = 0 }) => acc + occupiedYears, 0)
    : 0;

  const months = addresses
    ? addresses.reduce((acc, { occupiedMonths = 0 }) => acc + occupiedMonths, 0)
    : 0;

  return formatYearsMonths(years, months);
};

export const formatYearsMonths = (
  enteredYears: number,
  enteredMonths: number
): { years: number; months: number } => {
  const accMonths = enteredYears * 12 + enteredMonths;
  return {
    years: parseInt((accMonths / 12).toString()),
    months: accMonths % 12,
  };
};

/**
 * This function format an ISO date to a custom format (default: MMM d, yyyy)
 * @param isoDate A string representing an iso date
 * @param outputFormat Output format
 * @returns A formatted date
 */
export const formatISODateToCustomFormat = (
  isoDate: string | undefined | null,
  outputFormat = "MMM d, yyyy"
) => {
  if (!isoDate) return "-";

  const parsedDate = parseISO(isoDate);
  const formattedDate = format(parsedDate, outputFormat);

  return formattedDate;
};
