import {
  ADDRESS_SECTIONS,
  Applicant,
  ApplicantNavigationStates,
  Application,
  ApplicationTypeEnum,
  RegisteredAddress,
  TargetProperty,
  incomeArraySchema,
  mainApplicantInformationSchema,
  targetPropertyNotFoundCompletedSchema,
  targetPropertyCompletedSchema,
  coApplicantInformationSchema,
} from "@shared/constants";
import { refinancePropertySchema } from "@shared/constants/refinance-property";
import { renewingPropertySchema } from "@shared/constants/renewing-property";
import {
  HALF_MILLION_DOWN_PAYMENT_TRIGGER,
  getAddressNoAutoFillFields,
  getApplicationTypeByTransactionType,
  getMinimumDownPayment,
  getSumYearsMonths,
  getTargetPropertyValue,
  isTargetPropertyOnlyRental,
  isViableDownPayment,
} from "@shared/utils";

/// address history

export const getRegisteredAddressHasEnoughHistory = (
  applicant: Applicant
): boolean => {
  const { years, months } = getRegisteredAddressTime(applicant);

  // 3 years min of previous addresses
  return getIsEnoughHistory(years, months);
};

export const getIsEnoughHistory = (years: number, months: number): boolean =>
  years * 12 + months >= 1;

export const getRegisteredAddressTime = (
  applicant: Applicant
): { years: number; months: number } => {
  const addresses = getRegisteredAddressInfo(applicant);

  return getSumYearsMonths(addresses);
};

export const getRegisteredAddressInfo = (applicant: Applicant) =>
  applicant.addresses.map(
    (address) =>
      ({
        ...address,
        address: getAddressNoAutoFillFields(
          address.address,
          `${ADDRESS_SECTIONS.RegisteredAddress}_${address.id}`
        ),
      }) as RegisteredAddress
  );

/// applicant info

export const getIsApplicantInformationCompleted = (
  currentApplicant: Applicant
): boolean => {
  if (!currentApplicant) {
    return false;
  }

  const schema =
    currentApplicant.permissions === "MAIN_APPLICANT"
      ? mainApplicantInformationSchema
      : coApplicantInformationSchema;

  const { success } = schema.safeParse(currentApplicant);

  return success;
};

/// income

export const getIsApplicantIncomeComplete = (applicant: Applicant): boolean => {
  if (!applicant) {
    return false;
  }

  const { success } = incomeArraySchema.safeParse(applicant.income.employments);

  return success;
};

/// downpayment

export const getHasDownpayment = (applicant: Applicant) => {
  if (!applicant) {
    return false;
  }

  const assets = applicant.allAssets;

  const sumAmountUsedForDownPayment = (assets || []).reduce(
    (accumulator, asset) => {
      if ("amountUsedForDownPayment" in asset) {
        return accumulator + asset.amountUsedForDownPayment;
      }

      return accumulator;
    },
    0
  );

  return sumAmountUsedForDownPayment >= 1000;
};

/// banking details

export const hasBankingDetails = (applicant: Applicant) => {
  if (!applicant) return false;

  const institution =
    applicant.primaryBankingInstitution === "OTHER"
      ? applicant.primaryBankingInstitutionOther
      : applicant.primaryBankingInstitution;
  return !!institution;
};

/// bankruptcy

export const getIsBlockByBankruptcy = (applicants: Applicant[]) => {
  const mainApplicant = applicants.find(
    (applicant) => applicant.permissions === "MAIN_APPLICANT"
  );
  return mainApplicant?.hasConsumerProposalOrBankruptcyLast5yrs === "YES";
};

/// subject property

export const combineTargetProps = (
  targetProperty: TargetProperty | undefined,
  applicationType: ApplicationTypeEnum
) => {
  if (!targetProperty) {
    return false;
  }

  const schema = targetProperty.isFound
    ? targetPropertyCompletedSchema
    : targetPropertyNotFoundCompletedSchema;

  const { NEW, REFINANCE } = ApplicationTypeEnum;

  try {
    if (applicationType === NEW) {
      schema.parse(targetProperty);
    } else if (applicationType === REFINANCE) {
      refinancePropertySchema.parse(targetProperty);
    } else {
      renewingPropertySchema.parse(targetProperty);
    }
    return true;
  } catch (error) {
    return false;
  }
};

export const getHasMinPercentDownpayment = (
  application: Application
): boolean => {
  if (!isTargetProperty(application.property)) {
    return false;
  }

  const applicationType = getApplicationTypeByTransactionType(application.type);
  const downPayment = getDownPaymentAmount(
    Object.values(application.applicants)
  );
  const targetProperty = application.property;
  const isRental = isTargetPropertyOnlyRental(targetProperty);
  const propertyValue = getTargetPropertyValue(applicationType, targetProperty);
  const isViable = isViableDownPayment(propertyValue, downPayment, isRental);

  return isViable;
};

export const getDownPaymentAmount = (applicants: Applicant[]): number => {
  if (!applicants) return 0;

  return applicants.reduce((downpayment, applicant) => {
    return (
      downpayment +
      (applicant.allAssets
        ? applicant.allAssets.reduce((applicantDownpayment, asset) => {
            if ("amountUsedForDownPayment" in asset) {
              return applicantDownpayment + asset.amountUsedForDownPayment;
            }

            return applicantDownpayment;
          }, 0)
        : 0)
    );
  }, 0);
};

// https://www.codingem.com/javascript-how-to-limit-decimal-places/
// Issue with EPSILON:
// https://stackoverflow.com/questions/11832914/round-to-at-most-2-decimal-places-only-if-necessary/11832950#11832950
// 5123.275 -> 5123.28 but get 5123.27
// 519.805 -> 519.81 but get 519.8
const round = (precision: number) => (value: number) => {
  return Number(value.toFixed(precision));
};

const isTargetProperty = (property: unknown): property is TargetProperty => {
  return property !== undefined;
};

export const getDownPaymentPercentage = (application: Application): number => {
  // we do not have a property, so we can't calculate the down payment percentage
  if (!isTargetProperty(application.property)) {
    return 0;
  }

  const purchasePrice = application.property.purchasePrice;

  const downPaymentAmount = getDownPaymentAmount(
    Object.values(application.applicants)
  );

  const downPaymentPercentage = round(2)(
    // 12 411 / 100 000 = 0.12411 * 100 = 12.411 -> 12.41
    (downPaymentAmount / purchasePrice) * 100
  );

  return downPaymentPercentage;
};

export const getApplicationMinimumDownPayment = (application: Application) => {
  if (!isTargetProperty(application.property)) {
    return 0;
  }

  const targetProperty = application.property;
  const applicationType = getApplicationTypeByTransactionType(application.type);

  // if there is no property price we set default value to 500k
  // so we can calculate the down payment and fallback to the 5% rule of 500k
  const propertyValue =
    getTargetPropertyValue(applicationType, targetProperty) ||
    HALF_MILLION_DOWN_PAYMENT_TRIGGER;

  const isRentalProperty = isTargetPropertyOnlyRental(targetProperty);

  return getMinimumDownPayment(propertyValue, isRentalProperty);
};

export const getRemainingDownPayment = (application: Application) => {
  if (!isTargetProperty(application.property)) {
    return 0;
  }

  const currentDownPaymnet = getDownPaymentAmount(
    Object.values(application.applicants)
  );

  const minDownPaymentAmount = getApplicationMinimumDownPayment(application);

  return minDownPaymentAmount - currentDownPaymnet;
};

///

export const getIsApplicantComplete = (
  applicant: Applicant
): ApplicantNavigationStates & { downpayment: boolean } => {
  return {
    applicantInformation: getIsApplicantInformationCompleted(applicant),
    registeredAddress: getRegisteredAddressHasEnoughHistory(applicant),
    income: getIsApplicantIncomeComplete(applicant),
    otherIncome: applicant && applicant.otherIncomesSpecified,
    otherProperties: applicant && applicant.propertiesSpecified,
    downpayment: getHasDownpayment(applicant),
    bankingDetails: hasBankingDetails(applicant),
  };
};

//
// Zod Validation
//

// export const applicantInformationSchema = z.discriminatedUnion("permissions", [
//   z.object({
//     permissions: z.literal("MAIN_APPLICANT"),
//     salutation: z.string().trim().min(2),
//     firstName: z.string().trim().min(1),
//     lastName: z.string().trim().min(1),
//     dateOfBirth: z.string().trim().min(2),
//     phone: z.string().trim().min(10),
//     maritalStatus: z.string().trim().min(2),
//   }),
//   z.object({
//     permissions: z.literal("CO_APPLICANT_DEFAULT"),
//     salutation: z.string().trim().min(2),
//     firstName: z.string().trim().min(1),
//     lastName: z.string().trim().min(1),
//     dateOfBirth: z.string().trim().min(2),
//     phone: z.string().trim().min(10),
//     maritalStatus: z.string().trim().min(2),
//     relationToMainApplicant: z.string().trim().min(0).nullable(),
//   }),
// ]);

// export const incomeSchema = z.discriminatedUnion("incomeType", [
//   z.object({ incomeType: z.literal("NONE") }),
//   z.object({
//     incomeType: z.enum([
//       "SALARIED",
//       "HOURLY",
//       "HOURLY_COMMISSIONS",
//       "COMMISSIONS",
//       "SELF_EMPLOYED",
//       "PENSION",
//     ]),
//     salary: z.object({
//       base: z.object({
//         amount: z.number(),
//         frequency: z.string().trim().min(1),
//       }),
//     }),
//   }),
// ]);

// export const incomeArraySchema = z.array(incomeSchema).min(1);

// export const targetPropertySchema = z.object({
//   purpose: z.string().trim().min(1),
//   constructionType: z.string().trim().min(1),
//   propertyType: z.string().trim().min(1),
//   tenure: z.string().trim().min(1),
//   waterType: z.string().trim().min(1),
//   propertyStyle: z.string().trim().min(1),
//   yearBuilt: z.number().min(0),
//   estimatedPropertyValue: z.number().min(0),
// });

// export const targetPropertyNotFoundSchema = z.object({
//   purpose: z.string().trim().min(1),
//   propertyType: z.string().trim().min(1),
//   purchasePrice: z.number().min(0),
// });
