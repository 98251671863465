import { Button, Card, Flex, Heading, Typography } from "@nestoca/ui";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { Trans, useTranslation } from "next-i18next";
import { BsArrowRight } from "react-icons/bs";

import styles from "./loan-partner.module.scss";

const PARTNER = {
  loansCanada: {
    link: "https://loanscanada.ca/app/?affiliate_id=884",
    logo: "loan-canada.png",
    logoWidth: 67,
    logoHeight: 75,
    category: "Loans Canada",
  },
  pretsQuebec: {
    link: "https://pretsquebec.ca/app/?affiliate_id=884",
    logo: "prets-quebec.png",
    logoWidth: 64,
    logoHeight: 64,
    category: "Prêts Quebec",
  },
};

interface LoanPartnerProps {
  reason: "noMin" | "bankruptcy" | "tooManyProperties";
}

export const LoanPartnerCard = ({ reason }: LoanPartnerProps) => {
  const { t } = useTranslation("loan-partner");
  const { locale, basePath } = useRouter();
  // Old GAQ logic.
  const partner: keyof typeof PARTNER =
    locale === "fr" ? "pretsQuebec" : "loansCanada";

  return (
    <Card>
      <Flex direction="column" gap={4} className={styles["loan-partner"]}>
        <Flex align="center" justify="between">
          <Heading as="h1" className={styles["loan-partner__title"]}>
            {t(`loansPartner.title`)}
          </Heading>

          <Image
            src={`${basePath ?? ""}/assets/partners/${PARTNER[partner].logo}`}
            width={PARTNER[partner].logoWidth}
            height={PARTNER[partner].logoHeight}
            alt={t(`loansPartner.title`)}
            priority
          />
        </Flex>
        <Typography className={styles["loan-partner__content_1"]}>
          <Trans
            t={t}
            i18nKey={`loansPartner.${reason}.content_1`}
            components={[<em key={0} />, <b key={1} />]}
          />
        </Typography>
        <Typography className={styles["loan-partner__content_2"]}>
          <Trans
            t={t}
            i18nKey={`loansPartner.${reason}.content_2`}
            components={[<em key={0} />, <b key={1} />]}
          />
        </Typography>
        <Link
          data-dd-action-name="back"
          href={PARTNER[partner].link}
          passHref
          legacyBehavior
        >
          <Button
            as="a"
            size="large"
            variant="primary"
            rightIcon={<BsArrowRight />}
          >
            {t(`loansPartner.cta`)}
          </Button>
        </Link>
      </Flex>
    </Card>
  );
};
