import { MortgageType } from "@shared/constants/schema";

import { newMortgageChart } from "./new-mortgage-chart";
import { refinanceChart } from "./refinance-chart";
import { renewalChart } from "./renewal-chart";

import type { NewMortgageStep } from "./new-mortgage-chart";
import type { RefinanceStep } from "./refinance-chart";
import type { RenewalStep } from "./renewal-chart";

export const charts = {
  NEW: newMortgageChart,
  RENEWAL: renewalChart,
  REFINANCE: refinanceChart,
};

export type Charts = typeof charts;

export type StepByType<MT extends MortgageType> = MT extends "NEW"
  ? NewMortgageStep
  : MT extends "RENEWAL"
    ? RenewalStep
    : MT extends "REFINANCE"
      ? RefinanceStep
      : never;

export { NewMortgageStep, RefinanceStep, RenewalStep };
