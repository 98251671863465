import { api } from "@shared/api/client";
import {
  Account,
  DeleteAccountFormData,
  CallPreferencesPayload,
} from "@shared/constants";

export const getAccount = async () => {
  const { data } = await api.get<Account>("account");
  return data;
};

export const updateAccount = async (params: Account) => {
  const { data } = await api.put<Account>("account", params);
  return data;
};

export const deleteAccount = async (feedback: DeleteAccountFormData) => {
  const { data } = await api.post("/account/deletion", feedback);
  return data;
};

export const updateCallPreference = async (payload: CallPreferencesPayload) => {
  const { data } = await api.post<string | null>(
    `/account/call-preferences`,
    payload
  );

  return data;
};

export const createAccount = async (params: unknown) => {
  const { data } = await api.post<{
    account: Account;
    token: Record<string, string>;
  }>("accounts", params);
  return data;
};
