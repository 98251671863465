import { useCallback } from "react";

import {
  Box,
  Flex,
  Button,
  useBreakpointValue,
  OverflowMenu,
  Typography,
} from "@nestoca/ui";
import { useAnalytics } from "@shared/analytics";
import { TOAST_AUTOCLOSE_DELAY_IN_MS } from "@shared/constants";
import { useSupportContact } from "@shared/ui";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { ContactItem } from "./contact-item";
import styles from "./contact.module.scss";

import type { SupportInfoType } from "@shared/ui";

interface ContactProps {
  applicationId: number;
  isApplicationDashboard?: boolean;
  className?: string;
  showAsList?: boolean;
}

export const Contact = ({
  applicationId,
  isApplicationDashboard = false,
  className,
  showAsList = false,
}: ContactProps) => {
  const { t } = useTranslation("common");
  const { track } = useAnalytics();

  const isMobile = useBreakpointValue({ default: true, md: false });

  const { hasAssignedAdvisor, supportContactInfo } = useSupportContact({
    applicationId,
  });

  const button = () => (
    <Button
      variant="secondary"
      size={isMobile ? "small" : "medium"}
      className={clsx(styles["contact-button"], {
        [styles["application-dashboard"]]: isApplicationDashboard,
      })}
      data-dd-action-name="contact"
    >
      {t("button.contact")}
    </Button>
  );

  const header = hasAssignedAdvisor ? (
    <Box className={styles.header}>{t("advisor")}</Box>
  ) : (
    <>
      <Box className={styles.header}>{t("advisor")}</Box>
      <Typography as="p" className={styles["header__text"]} size={0}>
        {t("missingAdvisor")}
      </Typography>
    </>
  );

  const getOptionKey = (option: SupportInfoType) => option.id;

  const onClick = useCallback(
    (option: SupportInfoType) => {
      toast(t("contact.valueCopied", { data: option.type }), {
        type: "success",
        autoClose: TOAST_AUTOCLOSE_DELAY_IN_MS,
      });
    },
    [t]
  );

  const option = (option: SupportInfoType) => (
    <ContactItem
      optionItem={option}
      onClick={() => {
        onClick(option);
        track({
          event: "copy_contact_info",
          contact_type: option.id,
        });
      }}
    />
  );

  return (
    <>
      {isMobile && showAsList ? (
        <Flex className={className} direction="column">
          <Typography size={1} weight={7}>
            {t("advisorContactInfo")}
          </Typography>
          <Typography size={0}>
            {!hasAssignedAdvisor && t("missingAdvisor", { ns: "common" })}
          </Typography>
          {supportContactInfo.map((info) => {
            return (
              <ContactItem
                key={info.id}
                className={styles["contact-item"]}
                optionItem={info}
                onClick={() => {
                  onClick(info);
                  track({
                    event: "copy_contact_info",
                    contact_type: info.id,
                  });
                }}
              />
            );
          })}
        </Flex>
      ) : (
        <OverflowMenu
          options={supportContactInfo}
          getOptionKey={getOptionKey}
          button={button}
          header={header}
          renderOption={option}
          className={styles.menu}
          menuPlacement="left"
          closeOnOptionClick={false}
        />
      )}
    </>
  );
};
