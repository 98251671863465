import { MINIMUM_REFI_FINANCING_REQUIRED } from "@shared/constants";
import { FormRefinanceMortgage } from "@shared/constants/schema";

import { CallbackFn, ChartMachine } from "@hooks/use-wizard";

export type RefinanceChartContext = Partial<FormRefinanceMortgage> & {
  isAuthenticated: boolean;
};

export const minAmountRefianceCallback: CallbackFn<RefinanceChartContext> = (
  additionalFundAmount,
  { context }
) => {
  const mortgageAmount = context?.mortgageAmount || 0;

  if (mortgageAmount + additionalFundAmount < MINIMUM_REFI_FINANCING_REQUIRED) {
    return "loan-partner";
  }

  return "lender";
};

export const refinanceChart = {
  INITIAL: "owner-occupied",
  "owner-occupied": "property-value",
  "property-value": "mortgage-amount",
  "mortgage-amount": "refinance-amount",
  "refinance-amount": minAmountRefianceCallback,
  lender: (value, { context }) => {
    const isAuthenticated = context?.isAuthenticated;
    if (isAuthenticated) {
      return "rates";
    }

    return "contact";
  },
  contact: "rates",
  rates: { type: "final" },
} satisfies ChartMachine<RefinanceChartContext>;

export const refinanceCounterKeys = [
  "owner-occupied",
  "property-value",
  "mortgage-amount",
  "refinance-amount",
  "lender",
] as const;

// Because JS cannot garantee the order of keys, we need to do this
export const refinanceKeys = [
  ...refinanceCounterKeys,
  "contact",
  "rates",
] as const;

export type RefinanceCounterStep = (typeof refinanceCounterKeys)[number];
export type RefinanceStep = Exclude<keyof typeof refinanceChart, "INITIAL">;
