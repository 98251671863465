import { useMemo } from "react";

import { useGate } from "@nestoca/gate-react";
import { useTenant } from "@nestoca/multi-tenant";
import { useGetAssignedRepresentative } from "@shared/api/hooks/applications";
import { useBusinessHours } from "@shared/ui";
import { useTranslation } from "react-i18next";
import { BsTelephonePlus, BsEnvelope } from "react-icons/bs";

export type SupportInfoType = {
  id: string;
  type: string;
  data: string;
  canCopy?: boolean;
  link?: string;
  icon?: React.ReactNode;
};

export const useSupportContact = ({
  applicationId,
}: {
  applicationId: number;
}) => {
  const { t } = useTranslation("common");
  const { isBusinessTime } = useBusinessHours();
  const tenant = useTenant();

  const gate = useGate();
  const isExternalBroker = gate.getRole() === "externalbroker";

  const { data: assignedAdvisor } = useGetAssignedRepresentative(applicationId);

  // in case advisor info is missing or mapping is incorrect and assigned Advisor is Karim Benabdallah
  const hasAssignedAdvisor =
    assignedAdvisor &&
    assignedAdvisor.id &&
    assignedAdvisor.firstName !== "Karim" &&
    assignedAdvisor.lastName !== "Benabdallah";

  const supportContactInfo = useMemo(() => {
    const info: SupportInfoType[] = [];

    if (hasAssignedAdvisor) {
      info.push(
        {
          id: "name",
          type: t("advisorName", { ns: "applications" }),
          data: `${assignedAdvisor?.firstName} ${assignedAdvisor?.lastName}`,
        },
        {
          id: "phone",
          type: t("phone"),
          data: assignedAdvisor?.phone || "",
          canCopy: true,
          link: assignedAdvisor?.phone && `tel:${assignedAdvisor?.phone}`,
          icon: <BsTelephonePlus />,
        },
        {
          id: "email",
          type: t("email"),
          data: assignedAdvisor?.email || "",
          canCopy: true,
          link: assignedAdvisor?.email && `mailto:${assignedAdvisor?.email}`,
          icon: <BsEnvelope />,
        }
      );
    } else if (isExternalBroker) {
      info.push(
        {
          id: "name",
          type: t("underwriter", { ns: "common" }),
          data: "Tony Le",
        },
        {
          id: "phone",
          type: t("phone", { ns: "docs" }),
          data: "514-526-2442",
          canCopy: true,
          link: "tel:514-526-2442",
        },
        {
          id: "email",
          type: t("email", { ns: "docs" }),
          data: "tony.le@nesto.ca",
          canCopy: true,
          link: "mailto:tony.le@nesto.ca",
        }
      );
    } else {
      if (isBusinessTime) {
        info.push({
          id: "phone",
          type: t("phone"),
          data: t("phone.application"),
          canCopy: true,
          link: `tel:${t("phone.application")}`,
          icon: <BsTelephonePlus />,
        });
      }

      info.push({
        id: "email",
        type: t("email"),
        data: tenant?.applicationSupportEmail || "",
        canCopy: true,
        link: `mailto:${tenant?.applicationSupportEmail}`,
        icon: <BsEnvelope />,
      });
    }

    return info;
  }, [
    hasAssignedAdvisor,
    assignedAdvisor,
    isExternalBroker,
    isBusinessTime,
    tenant,
    t,
  ]);

  return {
    supportContactInfo,
    hasAssignedAdvisor,
  };
};
