import { useCallback } from "react";

import { useGate } from "@nestoca/gate-react";
import { Box, Modal, Typography } from "@nestoca/ui";
import { TOAST_AUTOCLOSE_DELAY_IN_MS } from "@shared/constants";
import { useApplicationDisabled, useSupportContact } from "@shared/ui";
import { Trans, useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { ContactItem } from "../contact/contact-item";

import styles from "./locked-application-modal.module.scss";

import type { SupportInfoType } from "@shared/ui";

type LockedApplicationModalPropsType = {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  applicationId: number;
  heading: JSX.Element;
  isFunded?: boolean;
  isLocked?: boolean;
  isClosed?: boolean;
};

export const LockedApplicationModal = ({
  isModalOpen,
  setIsModalOpen,
  applicationId,
  heading,
}: LockedApplicationModalPropsType) => {
  const { t } = useTranslation("applications");

  const {
    isFunded,
    isLocked,
    isClosed,
    isSubmittedToUnderwriter,
    isConditionalApproval,
    isFinalApproval,
  } = useApplicationDisabled(applicationId);

  const gate = useGate();
  const isExternalBroker = gate.getRole() === "externalbroker";

  const { supportContactInfo, hasAssignedAdvisor } = useSupportContact({
    applicationId,
  });

  const onClick = useCallback(
    (info: SupportInfoType) => {
      const text = isExternalBroker
        ? t(`copiedToClipboard.${info.id}.underwriter`, {
            data: info.type,
            ns: "applications",
          })
        : t("contact.valueCopied", { data: info.type });

      return toast(text, {
        type: "success",
        autoClose: TOAST_AUTOCLOSE_DELAY_IN_MS,
      });
    },
    [t, isExternalBroker]
  );

  const applicationHasStatus =
    isFunded ||
    isClosed ||
    isSubmittedToUnderwriter ||
    isConditionalApproval ||
    isFinalApproval;

  const isApplicationLocked = !applicationHasStatus && isLocked;

  const statusText = [
    { status: isFunded, text: "fundedApplication.modal.text" },
    { status: isClosed, text: "closedApplication.modal.text" },
    { status: isFinalApproval, text: "finalApprovalApplication.modal.text" },
    {
      status: isSubmittedToUnderwriter,
      text: "submittedToUnderwriterApplication.modal.text",
    },
    {
      status: isConditionalApproval,
      text: "conditionalApprovalApplication.modal.text",
    },
  ];

  return (
    <Modal
      visible={isModalOpen}
      scrollable
      className={styles["locked-application-modal"]}
      closeOnOutsideClick
      onClose={() => {
        setIsModalOpen(false);
      }}
    >
      <Modal.Header
        showCloseButton
        onClose={() => {
          setIsModalOpen(false);
        }}
        className={styles["modal-header"]}
        closeLabel={`${t("close", { ns: "common" })}`}
      >
        <Typography
          size={3}
          weight={7}
          className={styles["modal-header__heading"]}
        >
          {heading}
        </Typography>
      </Modal.Header>
      <Modal.Body>
        {isApplicationLocked && (
          <Typography
            className={styles["modal-body__text"]}
            size={0}
            weight={5}
          >
            <Trans
              t={t}
              i18nKey="lockedApplication.modal.noAccess"
              components={[<strong key={0} />, <strong key={1} />]}
            />
          </Typography>
        )}
        <Typography className={styles["modal-body__text"]} size={0} weight={5}>
          {isExternalBroker && isClosed
            ? t("closedApplication.modal.text", { ns: "docs" })
            : statusText.map(({ status, text }) => status && t(text))}
          {isApplicationLocked && t("lockedApplication.modal.uploadDocuments")}
        </Typography>
        <Typography className={styles["modal-body__text"]} size={0} weight={5}>
          {hasAssignedAdvisor
            ? applicationHasStatus
              ? t("lockedApplication.modal.contactAdvisorStatus")
              : isApplicationLocked
                ? t("lockedApplication.modal.contactAdvisorLocked")
                : null
            : isExternalBroker
              ? t("lockedApplication.modal.contactUnderwriterStatus", {
                  ns: "documents",
                })
              : t("lockedApplication.modal.missingAdvisorInfo")}
        </Typography>
        <Box className={styles.contact}>
          {supportContactInfo.map((info) => (
            <ContactItem
              optionItem={info}
              onClick={() => onClick(info)}
              className={styles["contact-item"]}
              key={info.id}
            />
          ))}
        </Box>
      </Modal.Body>
    </Modal>
  );
};
