import { useMemo } from "react";

import { OverflowMenu, Box, Flex } from "@nestoca/ui";
import {
  useGetApplicantById,
  useGetApplicants,
} from "@shared/api/hooks/applications";
import { DatadogMask } from "@shared/datadog";
import { getApplicantName } from "@shared/utils";
import clsx from "clsx";
import Link from "next/link";
import { useTranslation } from "react-i18next";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";

import styles from "./applicant-selector.module.scss";

type OptionType = {
  id: string;
  name: JSX.Element | string;
  selected: boolean;
  // first and last are used to apply the correct styles
  isFirst: boolean;
  isLast: boolean;
};

interface ApplicantSelectorProps {
  applicationId: number;
  selectedApplicantId?: number;
  getOptionHref?: (option: OptionType) => string;
  /** default value = false */
  withGarantor?: boolean;
}

const getOptionHrefDefault = (applicationId: number) => (option: OptionType) =>
  `/applications/${applicationId}/applicant/${option.id}`;

export const ApplicantSelectorMenu = ({
  applicationId,
  selectedApplicantId = 0,
  getOptionHref,
  withGarantor = false,
}: ApplicantSelectorProps) => {
  const { t } = useTranslation("applications");
  const { data: applicants } = useGetApplicants(applicationId, withGarantor);
  const { data: selectedApplicant } = useGetApplicantById(
    applicationId,
    selectedApplicantId
  );

  const options = useMemo(
    () =>
      applicants?.map<OptionType>((applicant, i, applicants) => {
        const applicantName = getApplicantName(
          applicant,
          t("unknownName", { ns: "common" })
        );

        return {
          id: `${applicant.applicantId}`,
          name: applicantName,
          selected: applicant.applicantId === selectedApplicantId,
          isFirst: i === 0,
          isLast: i === applicants.length - 1,
        };
      }) || [],
    [applicants, selectedApplicantId, t]
  );

  if (!applicants) return null;

  const getOptionKey = (option: OptionType) => option.id;

  const OptionRow = (option: OptionType) => {
    const href =
      getOptionHref?.(option) || getOptionHrefDefault(applicationId)(option);

    return (
      <Link href={href}>
        <DatadogMask
          as={Box}
          data-dd-action-name="menu option applicant"
          className={clsx(styles["menu-item"], {
            [styles["menu-item--selected"]]: option.selected,
            [styles["menu-item--first"]]: option.isFirst,
            [styles["menu-item--last"]]: option.isLast,
          })}
        >
          {option.name}
        </DatadogMask>
      </Link>
    );
  };

  const Button = (
    _value: OptionType | undefined,
    isMenuOpen: boolean | undefined
  ) => {
    if (!applicants) return null;
    if (!selectedApplicant) return null;

    const applicantName = getApplicantName(
      selectedApplicant,
      t("unknownName", { ns: "common" })
    );

    return (
      <Flex className={styles.button} gap={4}>
        <DatadogMask
          as={Box}
          className={styles["button-name"]}
          data-dd-action-name="dropdown applicant"
        >
          {applicantName}
        </DatadogMask>
        {isMenuOpen ? (
          <BsChevronUp size="1rem" color={"var(--color-brand)"} />
        ) : (
          <BsChevronDown size="1rem" color={"var(--color-brand)"} />
        )}
      </Flex>
    );
  };

  return (
    <>
      {applicants?.length > 1 ? (
        <OverflowMenu
          className={styles.menu}
          options={options}
          getOptionKey={getOptionKey}
          renderOption={OptionRow}
          button={Button}
        />
      ) : (
        <DatadogMask as={Box} data-dd-action-name="menu option applicant">
          {selectedApplicant?.firstName} {selectedApplicant?.lastName}
        </DatadogMask>
      )}
    </>
  );
};
