import { useCallback, useMemo, useState } from "react";

import { datadogRum } from "@datadog/browser-rum";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTenantFlags } from "@nestoca/multi-tenant";
import {
  Button,
  Flex,
  Modal,
  ModalProps,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@nestoca/ui";
import { useGetAccount, useMutateAccount } from "@shared/api/hooks/account";
import {
  SettingsFormData,
  settingsSchema,
  TOAST_AUTOCLOSE_DELAY_IN_MS,
} from "@shared/constants";
import Debug from "debug";
import { useRouter } from "next/router";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { DeleteAccountButton } from "../../delete-account/delete-account-button";
import { CommunicationPreferences } from "../../navbar/burger-menu/communication-preferences/communication-preferences";

import { SettingsForm } from "./settings-form";
import styles from "./user-settings-modal.module.scss";

const debug = Debug("nesto:user-settings");

type UserSettingsModalProps = Pick<ModalProps, "visible" | "onClose"> & {
  handleLogout: () => void;
};

export function UserSettingsModal({
  visible,
  onClose,
  handleLogout,
}: UserSettingsModalProps) {
  const { t } = useTranslation();
  const { data: account, isLoading, isError } = useGetAccount();
  const { enableDeleteAccount } = useTenantFlags();
  const { locale } = useRouter();

  const { enableEditAccountInfo, communicationPreference } = useTenantFlags();
  const accountMutation = useMutateAccount();
  const [isFormDirty, setIsFormDirty] = useState(false);

  const formData = useMemo(
    () =>
      ({
        firstName: account?.firstName || "",
        lastName: account?.lastName || "",
        phone: account?.phone,
        email: account?.email || "",
      }) as SettingsFormData,
    [account]
  );

  const methods = useForm<SettingsFormData>({
    reValidateMode: "onChange",
    resolver: zodResolver(settingsSchema),
    defaultValues: formData,
  });

  const resetValues = useCallback(
    (values: SettingsFormData) => {
      methods.reset(values);
    },
    [methods]
  );

  const onSubmit = useCallback(
    async (formData: SettingsFormData) => {
      if (!account) {
        return toast(t("failedToSave", { ns: "error" }), { type: "error" });
      }

      accountMutation.mutate(
        {
          ...account,
          ...formData,
          phoneSpecified: !!formData.phone,
        },
        {
          onSuccess: () => {
            debug("onSubmit onSuccess");
            resetValues(formData);
            toast(t("dataUpdatedSuccess", { ns: "applications" }), {
              type: "success",
              autoClose: TOAST_AUTOCLOSE_DELAY_IN_MS,
            });
          },
          onError: (error) => {
            debug("onSubmit onError", error);
            toast(t("dataUpdatedFailure", { ns: "applications" }), {
              type: "error",
            });
            datadogRum.addError(error);
          },
        }
      );

      return formData;
    },
    [account, accountMutation, t, resetValues]
  );

  if (!account || isLoading) return <Spinner />;

  if (visible && isError) {
    toast(t("getAccountError"), {
      type: "error",
      autoClose: TOAST_AUTOCLOSE_DELAY_IN_MS,
      toastId: "use-get-account-error",
    });
    onClose && onClose();
  }

  return (
    <Modal
      visible={visible}
      className={styles["user-settings-modal"]}
      closeOnOutsideClick
      onClose={onClose}
    >
      <FormProvider {...methods}>
        <Flex
          as="form"
          direction="column"
          onSubmit={methods.handleSubmit(onSubmit)}
          className={styles.form}
        >
          <Modal.Header showCloseButton onClose={onClose}>
            <h1 className={styles["header"]}>{t("settings")}</h1>
          </Modal.Header>
          <Modal.Body>
            <Tabs className={styles["tabs"]}>
              <TabList>
                <Tab>{t("personalInfo")}</Tab>
                {communicationPreference && (
                  <Tab>{t("communicationPreferences.title")}</Tab>
                )}
              </TabList>
              <TabPanels>
                <TabPanel key="personal-info" className={styles["tab-panel"]}>
                  <SettingsForm setIsFormDirty={setIsFormDirty} />
                  {enableEditAccountInfo && (
                    <Flex
                      align="center"
                      justify="end"
                      className={styles["ctas"]}
                    >
                      <Button
                        data-testid="submit"
                        variant="primary"
                        name="button"
                        type="submit"
                        disabled={!isFormDirty}
                      >
                        {t("saveSettings", { ns: "common" })}
                      </Button>
                    </Flex>
                  )}
                </TabPanel>
                {communicationPreference && (
                  <TabPanel
                    key="communication-preferences"
                    className={styles["tab-panel"]}
                  >
                    <CommunicationPreferences />
                  </TabPanel>
                )}
              </TabPanels>
            </Tabs>
          </Modal.Body>
          <Modal.Footer className={styles["footer"]}>
            <Flex align="center" justify="end" className={styles["ctas"]}>
              <Button variant="ghost" onClick={onClose}>
                {t("close")}
              </Button>
              <Button variant="alternative" onClick={handleLogout}>
                {t("signOut")}
              </Button>
              {enableDeleteAccount && (
                <DeleteAccountButton
                  onClick={() => {
                    onClose && onClose();
                    window.location.href = `${window.location.origin}${locale !== "en" ? `/${locale}` : ""}/delete-account`;
                  }}
                />
              )}
            </Flex>
          </Modal.Footer>
        </Flex>
      </FormProvider>
    </Modal>
  );
}
