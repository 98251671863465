import { useBreakpointValue, Flex, Typography } from "@nestoca/ui";
import { HomeHeader } from "@shared/ui";
import { useTranslation } from "next-i18next";

import styles from "./no-active-applications.module.scss";

import type { BreakpointValueType } from "@components/home-page";

export const NoActiveApplications = () => {
  const { t } = useTranslation("common");
  const breakpointValue = useBreakpointValue({
    default: { wrappersGap: 7 } as BreakpointValueType,
    md: { wrappersGap: 5 } as BreakpointValueType,
  });

  return (
    <Flex direction="column" align="center" className={styles["no-active-applications"]}>
      <Flex
        className={styles["no-active-applications__wrapper"]}
        direction="column"
        gap={breakpointValue?.wrappersGap}
      >
        <HomeHeader className={styles["no-active-applications__header"]} />
        <Flex
          className={styles["no-active-applications__content"]}
          align="center"
          direction="column"
        >
          <Typography size={1} weight={7}>
            {t("noActiveApplications.title")}
          </Typography>
          <Typography size="00">
            {t("noActiveApplications.description")}
          </Typography>
        </Flex>
      </Flex>
    </Flex>
  );
};
