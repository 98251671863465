import { ApplicationState, ApplicationTypeEnum } from "@shared/constants";
import {
  getTargetPropertyValue,
  isTargetPropertyOnlyRental,
  isViableDownPayment,
} from "@shared/utils";

import type { Applicant, Application, TargetProperty } from "@shared/constants";

export const isApplicationComplete = ({
  applicationState,
  locked,
}: Application) =>
  Boolean(applicationState !== ApplicationState.Created && locked);

/**
 * Returns the total down payment amount from all applicants
 * @param {Applicant[]} applicants the list of applicants
 */
export const getDownPaymentAmount = (
  applicants: Record<number, Applicant>
): number => {
  if (!applicants) return 0;

  // TODO: there is a potential bug here - we don't check if the asset is marked as willBeUsedForDownPayment
  return Object.values(applicants).reduce((downpayment, applicant) => {
    return (
      downpayment +
      (applicant.allAssets
        ? applicant.allAssets.reduce((applicantDownpayment, asset) => {
            if ("amountUsedForDownPayment" in asset) {
              return applicantDownpayment + asset.amountUsedForDownPayment;
            }

            return applicantDownpayment;
          }, 0)
        : 0)
    );
  }, 0);
};

export const isDownPaymentValid = (
  applicationType: ApplicationTypeEnum,
  downPayment: number,
  targetProperty?: TargetProperty
) => {
  if (!targetProperty) return false;
  if (applicationType !== ApplicationTypeEnum.NEW) return true;

  const isRental = isTargetPropertyOnlyRental(targetProperty);
  const propertyValue = getTargetPropertyValue(applicationType, targetProperty);

  const isViable = isViableDownPayment(propertyValue, downPayment, isRental);

  return isViable;
};
