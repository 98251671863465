import { MINIMUM_REFI_FINANCING_REQUIRED } from "@shared/constants";
import { FormRenewalMortgage } from "@shared/constants/schema";
import { validateUnderwater80, validateUnderwater95 } from "@shared/utils";

import { CallbackFn, ChartMachine } from "@hooks/use-wizard";

export type RenewalChartContext = Partial<FormRenewalMortgage> & {
  isAuthenticated: boolean;
};

export const minAmountRenewalCallback: CallbackFn<RenewalChartContext> = (
  mortgageAmount,
  { context }
) => {
  if (mortgageAmount < MINIMUM_REFI_FINANCING_REQUIRED) {
    return "loan-partner";
  }

  if (!context?.propertyValue) {
    return "underwater-95";
  }

  const isUnderwater95 = validateUnderwater95(
    context.propertyValue || 0,
    mortgageAmount
  );

  if (isUnderwater95) {
    return "underwater-95";
  }

  return "down-payment-ratio";
};

export const downpaymentRationRenewalCallback: CallbackFn<
  RenewalChartContext
> = (downPaymentRatio, { context }) => {
  if (!context?.propertyValue || !context?.mortgageAmount) {
    return "underwater-80";
  }

  const isUnderwater80 = validateUnderwater80(
    downPaymentRatio,
    context.propertyValue || 0,
    context.mortgageAmount || 0
  );

  if (isUnderwater80) {
    return "underwater-80";
  }

  return "lender";
};

export const renewalChart = {
  INITIAL: "owner-occupied",
  "owner-occupied": "property-value",
  "property-value": "mortgage-amount",
  "mortgage-amount": minAmountRenewalCallback,
  "down-payment-ratio": downpaymentRationRenewalCallback,
  lender: "renewal-schedule",
  "renewal-schedule": (value, { context }) => {
    const isAuthenticated = context?.isAuthenticated;

    if (isAuthenticated) {
      return "rates";
    }

    return "contact";
  },
  contact: "rates",
  rates: { type: "final" },
} satisfies ChartMachine<RenewalChartContext>;

// Because JS cannot garantee the order of keys, we need to do this
export const renewalCounterKeys = [
  "owner-occupied",
  "property-value",
  "mortgage-amount",
  "down-payment-ratio",
  "lender",
  "renewal-schedule",
] as const;
export const renewalKeys = [...renewalCounterKeys, "contact", "rates"] as const;

export type RenewalCounterStep = (typeof renewalCounterKeys)[number];
export type RenewalStep = Exclude<keyof typeof renewalChart, "INITIAL">;
